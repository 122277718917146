const routes = {
  home: "/",
  contact: "/contact",
  // PrivacyPolicy: "/policy/privacy-policy",
  // TemrsOfService: "/policy/temrs-of-service",
  TemrsOfService: "/policy/temrs-of-service",
  PrivacyPolicy: "/policy/privacy-policy",
};

export default routes;
