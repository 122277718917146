import { Route, Routes } from "react-router-dom";
import router from "constant/router";
import React, { Suspense } from "react";
import LoadingSpin from "component/base/Loading";

const Home = React.lazy(() => import("./home"));
const Policy = React.lazy(() => import("./policy"));

export default (
  <Suspense fallback={<LoadingSpin />}>
    <Routes>
      <Route path={router.home} element={<Home />} />
      {/* <Route path={router.Policy} element={<Policy />} /> */}
      <Route path={router.PrivacyPolicy} element={<Policy />} />
      <Route path={router.TemrsOfService} element={<Policy />} />
    </Routes>
  </Suspense>
);
