import RouteContainer from "./pages/index";
import "./App.css";
import { GlobalStyles } from "twin.macro";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
function App() {
  return (
    <>
      <GlobalStyles />
      <div>{RouteContainer}</div>
    </>
  );
}

export default App;
